<template>
  <div class="tw-container border border-1 border-dark p-2">
    <!-- 標題 -->
    <p class="tw-border-start fw-bolder tw-text-size20">VIP/群組管理</p>
    <hr />
    <!-- 預設開放VIP等級 -->
    <div class="d-inline-block w-100">
      <p class="fw-bolder mb-2 p-1">。預設開放VIP等級</p>
      <select
        class="form-select mb-3"
        id="VipGrate"
        v-if="!storeVips.loading"
        v-model="selectStoreVipId"
      >
        <option value="不選擇" disabled>選擇VIP等級</option>
        <option value="0">不開放</option>
        <option :value="data.id" v-for="data in storeVips.data" :key="data.id">
          {{ data.name }}
        </option>
      </select>
      <AreaLoading v-else></AreaLoading>
    </div>
    <div class="row">
      <!-- VIP -->
      <div class="col-lg p-0 pe-1 mb-2">
        <!-- VIP列表 -->
        <p class="fw-bolder mb-2">。VIP列表</p>
        <AreaLoading v-if="storeVips.loading"></AreaLoading>
        <table class="table" v-else>
          <thead>
            <tr>
              <th scope="col">等級</th>
              <th scope="col">名稱</th>
              <th scope="col">操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in storeVips.data" :key="item.id">
              <td>LV{{ item.level }}</td>
              <td>{{ item.name }}</td>
              <td>
                <button
                  class="tw-btn tw-btn-success"
                  @click="showModal('storeVip', item)"
                >
                  修改
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- 顧客 -->
      <div class="col-lg p-0 ps-1 mb-2">
        <!-- 顧客群組 -->
        <p class="fw-bolder mb-2">。顧客群組</p>
        <AreaLoading v-if="customerGroups.loading"></AreaLoading>
        <table class="table" v-else>
          <thead>
            <tr>
              <th scope="col">群組編號</th>
              <th scope="col">名稱</th>
              <th scope="col">操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in customerGroups.data" :key="item.id">
              <td>群組{{ item.serialNumber }}</td>
              <td>{{ item.name }}</td>
              <td>
                <button
                  class="tw-btn tw-btn-success"
                  @click="showModal('customerGroups', item)"
                >
                  修改
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- 編輯VIP modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editStoreVipModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">編輯VIP</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditVip"
              placeholder="輸入VIP名稱(10字)"
              maxlength="10"
              v-model="editVipData.name"
            />
            <label for="EditVip">輸入VIP名稱(10字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="editVip">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯群組名稱 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editCustomerGroupsModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">編輯群組名稱</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditCustomerGroup"
              placeholder="輸入群組名稱(10字)"
              maxlength="10"
              v-model="editCustomerGroupData.name"
            />
            <label for="EditCustomerGroup">輸入群組名稱(10字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="editGroup"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// mixin
import { Permission } from '@/methods/mixins/permission';

export default {
  data() {
    return {
      // modal
      editStoreVipModal: {},
      editCustomerGroupsModal: {},
      // data
      serverToken: '',
      storeId: 0,
      // 編輯VIP
      editVipData: {},
      // 編輯群組
      editCustomerGroupData: {},
      // 賣場資訊
      storeInfo: {},
      // VIP 等級
      selectStoreVipId: 0,
      // 賣場 VIP 資訊
      storeVips: {
        loading: false,
        data: []
      },
      // 顧客群組資訊
      customerGroups: {
        loading: false,
        data: []
      }
    }
  },
  mixins: [Permission],
  created() {
    this.initialization()
    this.getStoreInfo()
  },
  mounted() {
    this.createModals([
      'editStoreVipModal','editCustomerGroupsModal'
    ])
  },
  watch: {
    selectStoreVipId: {
      handler(val, oldVal) {
        if (val !== this.storeInfo.storeVipId) {
          this.setVip(val)
        }
      }
    } 
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 開啟 modal
    showModal(status, item) {
      if (status === 'storeVip') {
        this.editVipData = JSON.parse(JSON.stringify(item))
        this.editStoreVipModal.show()
      } else if (status === 'customerGroups') {
        this.editCustomerGroupData = JSON.parse(JSON.stringify(item))
        this.editCustomerGroupsModal.show()
      }
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getStoreVips,getCustomerGroups}'
        }
      ]
      this.storeVips = {
        loading: true,
        data: []
      }
      this.customerGroups = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            // 賣場資訊 
            vm.storeInfo = storeInfo
            // 賣場 VIP 等級
            vm.selectStoreVipId = storeInfo.storeVipId ? storeInfo.storeVipId : 0
            // 賣場 VIP 資訊
            vm.storeVips = {
              loading: false,
              data: storeInfo.storeVips
            }
            // 顧客群組資訊 
            vm.customerGroups = {
              loading: false,
              data: storeInfo.customerGroups
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 設定 VIP 等級
    setVip(setVipId) {
      const vm = this
      this.$methods.switchLoading('show')
      const setVipApi = `${process.env.VUE_APP_API}/store/setAttribute`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        id: this.storeId,
        attributes: {
          setStoreVipId: setVipId
        }
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: setVipApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getStoreInfo()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })

    },
    // 編輯 VIP 名稱
    editVip() {
      const vm = this
      if (!this.editVipData.name.trim(' ')) return this.SweetAlert('other', 'VIP名稱不能為空')
      this.$methods.switchLoading('show')
      const editVipApi = `${process.env.VUE_APP_API}/storeVip/update`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        id: this.editVipData.id,
        name: this.editVipData.name.trim(' ')
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: editVipApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.editStoreVipModal.hide()
            vm.getStoreInfo()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 編輯顧客群組名稱
    editGroup() {
      const vm = this
      if (!this.editCustomerGroupData.name.trim(' ')) return this.SweetAlert('other', '顧客群組名稱不能為空')
      this.$methods.switchLoading('show')
      const editGroupApi = `${process.env.VUE_APP_API}/customerGroup/update`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        id: this.editCustomerGroupData.id,
        name: this.editCustomerGroupData.name.trim(' ')
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: editGroupApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.editCustomerGroupsModal.hide()
            vm.getStoreInfo()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })

    },
  }
}
</script>